
import { OtpAuthenticationData } from "@/types/localtypes";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { isRestClientError, RegularLoginResponse, storeErrorsMapping, UserDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({
  name: "LoginAuthenticator",
  $_veeValidate: { validator: "new" },
  components: {
    JFTextField,
    JFFormControl,
  },
})
export default class LoginAuthenticator extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("showAppLoadingMask", { namespace: "application" })
  showAppLoadingMask!: () => void;
  @Action("hideAppLoadingMask", { namespace: "application" })
  hideAppLoadingMask!: () => void;
  @Action("setUser", { namespace: "user" })
  setUser!: (user: UserDTO) => Promise<any>;
  @Getter("otpAuthenticationData", { namespace: "authentication" })
  otpAuthenticationData!: OtpAuthenticationData;
  otp: string = "";

  async mounted() {
    ((this.$refs["otp-input"] as Vue).$el.querySelector("input") as HTMLInputElement).focus();
  }

  toLogin() {
    this.$router.push({ path: "/login" });
  }

  get sendButtonDisabled() {
    return !this.otpAuthenticationData || this.errors.any() || !this.otp;
  }

  get shouldDisplayBackButton() {
    return !!this.otpAuthenticationData && !this.otpAuthenticationData.fromSSO;
  }

  async checkOTP() {
    if (!(await this.$validator.validateAll())) {
      return;
    }
    this.showAppLoadingMask();
    try {
      const loginResponse: RegularLoginResponse | null = await this.$jfUsers.loginOtp({
        otp: this.otp,
        otpTokenKeyLink: this.otpAuthenticationData.otpTokenKeyLink,
      });
      this.hideAppLoadingMask();

      if (!loginResponse) {
        throw new Error("OTP login response is null");
      }

      this.onOTPValid(loginResponse);
    } catch (e) {
      this.$log.error(e);
      this.hideAppLoadingMask();
      if (e.httpStatus === 401) {
        // 401 will be intercepted by Http interceptors
        return;
      }
      let errorMessage = e.httpStatusText || e;
      if (isRestClientError(e) && e.httpBody) {
        if (e.httpBody.result === storeErrorsMapping.mfa.login.notMatchingOTPCode) {
          errorMessage = this.$jfMessages.mfa_error_otp_not_match;
        }
      }
      if (e.httpStatus === 429) {
        errorMessage = this.$jfMessages.too_many_requests;
      }
      if (e.httpStatus === 500) {
        errorMessage = this.$jfMessages.app_something_went_wrong;
      }
      this.$jfNotification.error({ text: errorMessage });
    }
  }

  async onOTPValid(loginResponse: RegularLoginResponse) {
    this.globalBus.$emit("shouldHandleLoginSuccess", loginResponse);
  }
}
